<template>
  <div class="bodycont">

    <comHeader />
    <div class="main">
      <infonavtab :cindex='cindex'  />
      <div class="infodiv">
        <!-- <navTitle :navData='navData' /> -->
        <div class="search">
          <div class="demo-input-suffix">
           标题搜索：
             <el-input style="width: 200px;" clearable @clear='clear'  v-model="searchValue"></el-input>
             </div>
             <div class="demo-input-suffix">
              <el-button type="primary" @click="searchBtn">搜索</el-button>
             </div>
        </div>
        <div class="msgdiv">

          <ul class="list_txt" v-if="totalSize>0">
            <li v-for="item in dataList" :key='item.newId' @click="tourl(item)">
              <div class="title">
                <img src='../../assets/images/icon2.png' />
                <span>{{item.title}}</span>
              </div>
              <span class="time">{{item.submittime}}</span>
            </li>
          </ul>
          <div class="pages" v-if="totalSize>10">
            <el-pagination background layout="prev, pager, next" :current-page="page.page" :page-size="page.limit"
            	 :total="totalSize" @current-change="handleCurrentChange"></el-pagination>

          </div>
        </div>
      </div>
    </div>

    <bNav />

  </div>

</template>

<script>
  import comHeader from '@/components/header.vue'
  import bNav from '@/components/bottomNav.vue'
  import infonavtab from './components/navtabnews.vue'
import api from '@/config/api'
  import {
    getRequst,postRequest
  } from '@/api/index'
  export default {
    components: {
      infonavtab,
      comHeader,
      bNav,
    },
    data() {
      return {
        cindex:2,
        dataList:[],
        page:{
          page:1,
          limit:20,
        },
        totalSize:0,
         searchValue:'',
      }
    },
    created() {

    },
    mounted() {
        this.getList()
    },
    methods: {
      searchBtn(){
        this.getList()
      },
      clear(){
        this.searchValue = ''
        this.getList()
      },
      //获取列表
      getList() {
        let data = {
          page:this.page.page,
          limit:this.page.limit,
          newstype:2,
          checkstate:3,
        }
        data.title=this.searchValue
        postRequest(api.newsPolicy, data).then(res => {
           this.dataList = res.page.list
           this.totalSize = Number(res.page.totalCount)
           if(this.totalSize==0){
             this.$message({
               type: 'info',
               message: '暂无相关数据'
             });
           }
        })

      },
      handleCurrentChange(val) {
        this.page.page = val;
        this.getList()
      },
      //详情页面
     tourl(item){
       this.$router.push({
         path:'/newsdetails',
         query:{
           id:item.newId,
           url:'news',
           name:'成果新闻'
         }
       })
     }
    }
  }
</script>

<style lang="less" scoped>
  .bodycont {
    margin: 0px;
    background-size: 100% 100%;
    background-attachment: fixed;
  }

  .main {
    width:1190px;
    margin: 20px auto;
    min-height:73vh;
    display: flex;
    justify-content: space-between;
  }
  .infodiv{
    width: 80%;
  }
  .search{
    display: flex;
     justify-content: flex-start; ;
    margin-bottom: 20px;
    .demo-input-suffix{
      margin-right: 20px;
    }
  }

  .list_txt {

    list-style: none;

    li {
      height: 50px;
      border-bottom: 1px solid #e6e6e6;
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      cursor: pointer;
      .title {
        display: flex;
         justify-content: flex-start; 
        align-items: center;
       width: 80%;
       text-overflow: ellipsis;
       white-space: nowrap;

       overflow: hidden;
        img {
          width: 30px;
          height: 30px;
        }

        span {
          display: inline-block;
          font-size: 16px;
          color: #333333;
          line-height: 50px;
          padding-left: 6px;

        }
      }

      .time {
        display: block;
        line-height: 50px;
        font-size: 16px;
        color: #333333;
      }
    }
  }
  .msgdiv{
    position: relative;

    .pages {
      width: 80%;
      margin: 20px auto;
      text-align: center;
    }
  }
</style>
